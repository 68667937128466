$(document).ready(function() {
    
    $('header ul').on('click', 'li:first-child a', function(e) {
        e.preventDefault();
        $('nav').slideToggle();
        $('header ul li a span').toggleClass('active');
    });
    
    $('.home__blocks .block').hover(
        function() {
            $('.home__blocks .block').addClass('inactive');
        },
        function() {
            $('.home__blocks .block').removeClass('inactive');
        }
    );
    
    function animateText(child, delay, startAgain) {
        
        var next, 
            txt = $('body.homepage h1 span:nth-child(' + child + ')').data('text').split('');
        
        if(startAgain) {
            $('body.homepage h1 span').html('');
        }
        
        if(child > 1) {
            $('body.homepage h1 span:nth-child(' + (child - 1) + ')').html('');
        }
        
        txt.forEach(function(chr, i) {
            setTimeout(function() {
                $('body.homepage h1 span:nth-child(' + child + ')').append(chr);
            }, 100*(i+1));
        });
        
        if(child == 3) {
            next = 1;
            startAgain = true;
        } else {
            next = (child + 1);
        }
        
        setTimeout(function() {
            animateText(next, delay, startAgain);
        }, delay);
                   
    }
    
    setTimeout(function() {
        animateText(1, 2500, false);
    }, 500);
    
});